@use '@angular/material' as mat;

@use './app/sidenav-theme' as sidenav;
@use './app/colors-theme' as colors;

@import '@angular/material/core/theming/theming';
@include mat.core();
@import './palettes.scss';

$light-primary: mat.define-palette($md-navy-blue, 400);
$light-accent: mat.define-palette($md-metallic-gold, 400);
$light-warn: mat.define-palette(mat.$red-palette);

$light-theme: mat.define-light-theme((color: (primary: $light-primary,
                accent: $light-accent,
                warn: $light-warn,
            ),
            typography: mat.define-typography-config(),
            density: 0,
        ));

$dark-primary: mat.define-palette($md-navy-blue, 500);
$dark-accent: mat.define-palette($md-metallic-gold, 500);
$dark-warn: mat.define-palette(mat.$red-palette);

$dark-theme: mat.define-dark-theme((color: (primary: $dark-primary,
                accent: $dark-accent,
                warn: $dark-warn,
            ),
            typography: mat.define-typography-config(),
            density: 0,
        ));

@include mat.all-component-themes($light-theme);
@include sidenav.theme($light-theme);
@include colors.theme($light-theme);

.dark-theme {
    @include mat.all-component-colors($dark-theme);
    @include sidenav.theme($dark-theme);
    @include colors.theme($dark-theme);
}