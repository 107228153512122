.cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.card-item {
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    margin-bottom: 1rem;
    padding: 0.5rem;

    @media (min-width: 1000px) {
        max-width: 24%;
    }
}

.card-item-group {
    label {
        font-size: 0.7rem;
    }
}