.list-unstyled {
    list-style: none;
    list-style-type: none;
}

.size-24 {
    width: 24px !important;
    height: 24px !important;
}

.no-shadow {
    box-shadow: none !important;
}