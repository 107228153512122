@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $primary: mat.get-theme-color($theme, primary, default);
    $accent: mat.get-theme-color($theme, accent, default);
    $warn: mat.get-theme-color($theme, warn, default);

    .primary-color-bg {
        background-color: $primary !important;
    }

    .primary-color {
        color: $primary !important;
    }

    .primary-color-50 {
        background-color: mat.get-theme-color($theme, primary, 50) !important;
    }

    .accent-color-bg {
        background-color: $accent !important;
    }

    .accent-color {
        color: $accent !important;
    }

    .warn-color-bg {
        background-color: $warn !important;
    }

    .warn-color {
        color: $warn !important;
    }
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
}