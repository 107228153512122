.sidenav-container {
    height: 100%;
}

.sidebar-header {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    height: $toolbar-height-mobile;
    padding: 0 10px;
    overflow: hidden;

    @media (min-width: 481px) {
        height: $toolbar-height-desktop;
    }
}

.sidebar-main {
    height: calc(100% - #{$toolbar-height-mobile});
    height: -moz-calc(100% - #{$toolbar-height-mobile});
    height: -webkit-calc(100% - #{$toolbar-height-mobile});
    overflow: auto;

    @media (min-width: 481px) {
        height: calc(100% - #{$toolbar-height-desktop});
        height: -moz-calc(100% - #{$toolbar-height-desktop});
        height: -webkit-calc(100% - #{$toolbar-height-desktop});
    }
}

.sidemenu {
    width: $sidenav-width;
    margin: 0;
    padding: 0;
    list-style: none;
}

.branding {
    color: inherit;
    white-space: nowrap;
    text-decoration: none;

    .branding-name {
        font-size: 1.5rem;
        margin-left: 1rem;
    }
}