@use 'sass:map';
@use '@angular/material' as mat;
@import 'variables.scss';

@mixin color($theme) {
    .sidenav {
        .sidemenu {
            a {
                color: mat.get-theme-color($theme, foreground, text);
            }

            .sidemenu-item {
                &:hover {
                    background: mat.get-theme-color($theme, background, hover);
                }

                &.open {
                    background: rgba(0, 0, 0, .02);
                }

                &.active {
                    >a {
                        color: mat.get-theme-color($theme, accent, default);
                    }
                }
            }
        }

        .sidebar-header {
            color: mat.get-theme-color($theme, foreground, text);
            background: mat.get-theme-color($theme, background, app-bar);
            border-bottom: 1px solid $boder-color;
        }
    }

    .list-item-active,
    .list-item-active span {
        font-weight: bold !important;
        color: mat.get-theme-color($theme, primary, default) !important;
    }
}

@mixin theme($theme) {
    @if mat.theme-has($theme, color) {
        @include color($theme);
    }
}