// Layout
$gutter: 16px !default;

// Toolbar
$toolbar-height-desktop: 64px !default;
$toolbar-height-mobile: 56px !default;

// Border
$boder-color: rgba(0, 0, 0, .12);

//Logo
$logo-height: 50px !default;

// Sidenav
$sidenav-width: 240px !default;
$sidenav-collapsed-width: 50px !default;
$sidenav-width-mobile: 280px !default;