.mat-mdc-slide-toggle {
    display: flex !important;
}

.mat-mdc-progress-bar {
    z-index: 99;
    position: fixed !important;
}

.mdc-list {
    padding: 0 !important;
}

.mat-expansion-panel-header {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.mat-expansion-panel-body {
    padding: 0 !important;
    padding-left: 1rem !important;
}

.mat-drawer-inner-container{
    height: auto !important;
    width: auto !important;
}