.center-form-container {
    min-width: 90%;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (min-width: 481px) {
    .center-form-container {
        min-width: 480px;
    }
}

.form-horizontal {
    display: flex;
    flex-direction: column;
}

.mat-mdc-form-field {
    width: 100%;
}