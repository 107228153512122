@import './variables.scss';

.container-wrap {
    height: 100%;
}

.main-content {
    padding: $gutter;
    height: calc(100% - #{$toolbar-height-mobile});
    height: -moz-calc(100% - #{$toolbar-height-mobile});
    height: -webkit-calc(100% - #{$toolbar-height-mobile});

    @media (min-width: 481px) {
        height: calc(100% - #{$toolbar-height-desktop});
        height: -moz-calc(100% - #{$toolbar-height-desktop});
        height: -webkit-calc(100% - #{$toolbar-height-desktop});
    }
}

.scrollbar-none {
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
}